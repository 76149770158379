import {
    createBrowserRouter
} from "react-router-dom";
import MainPage from "./pages/MainPage";
import LoginPage from "./pages/LoginPage";
import LoginPasswordlessPage from "./pages/LoginPasswordlessPage";
import ErrorPage from "./pages/ErrorPage";
import AboutUsPage from "./pages/AboutUsPage";
import DashboardPage from "./pages/DashboardPage";
import ScientificPaperPage from "./pages/ScientificPaperPage";
import ContactUsPage from "./pages/ContactUsPage";
import CookiesPolicyPage from "./pages/CookiesPolicyPage";
import TermsofServicePage from "./pages/TermsofServicePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import ReturnPaymentPage from "./pages/ReturnPaymentPage";
import CreditsPaymentPage from "./pages/CreditsPaymentPage";
import ServiceDownPage from "./pages/ServiceDownPage";
import StatusPage from "./pages/StatusPage";
import PaymentPage from "./pages/PaymentPage";
import Logoutage from "./pages/LogoutPage";
import SearchPage from "./pages/SearchPage";
import DisplayPage from "./pages/DisplayPage";
import BlogPage from "./pages/blog/BlogPage";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <MainPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/payment/:status",
        element: <PaymentPage />,
    },
    {
        path: "/dashboard",
        element: <DashboardPage />,
    },
    {
        path: "/signin",
        element: <LoginPage />,
    },
    {
        path: "/login-passwordless/:token",
        element: <LoginPasswordlessPage />,
    },
    {
        path: "/logout",
        element: <Logoutage />,
    },
    {
        path: "/about-us",
        element: <AboutUsPage />,
    },
    {
        path: "/scientific-paper",
        element: <ScientificPaperPage />,
    },
    {
        path: "/search",
        element: <SearchPage />,
    },
    {
        path: "/document/:index/:id",
        element: <DisplayPage />,
    },
    {
        path: "/contact-us",
        element: <ContactUsPage />,
    },
    {
        path: "/cookies-policy",
        element: <CookiesPolicyPage />,
    },
    {
        path: "/terms-of-service",
        element: <TermsofServicePage />,
    },
    {
        path: "/privacy-policy",
        element: <PrivacyPolicyPage />,
    },
    {
        path: "/return-payment",
        element: <ReturnPaymentPage />,
    },
    {
        path: "/credits-payment",
        element: <CreditsPaymentPage />,
    },
    {
        path: "/service-down",
        element: <ServiceDownPage />,
    },
    {
        path: "/status",
        element: <StatusPage />,
    },
    {
        path: "/blog/chatgpt4-proxy",
        element: <BlogPage />,
    },
]);

export default router;
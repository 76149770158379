import React from 'react';
import {
  Heading,
  Text,
  Stack,
  Container,
  useColorModeValue,
} from '@chakra-ui/react'

const CookiesPolicyPage: React.FC = () => {

  return (
    <Container maxW={'5xl'} py={12}>
      <Stack spacing={4}>
        <Text
          textTransform={'uppercase'}
          color={'blue.400'}
          fontWeight={600}
          fontSize={'sm'}
          bg={useColorModeValue('blue.50', 'blue.900')}
          p={2}
          alignSelf={'flex-start'}
          rounded={'md'}>
          Legal Information
        </Text>
        <Heading>Cookies Policy</Heading>
        <Text color={'gray.500'} fontSize={'lg'}>
          Last Updated: 2023-11-16
        </Text>




        <Text>Welcome to lawly.cz and Soudnirozhodnuti.cz. Your privacy is of utmost importance to us. This cookie policy explains how we use cookies and similar technologies in our search engine and prediction tool to enhance your experience while respecting your privacy.</Text>

        <Heading size="md">What are Cookies?</Heading>
        <Text>Cookies are small data files stored on your device when you visit a website. They are widely used to ensure the efficient functioning of websites, remember user preferences, and provide anonymous tracking data to third party applications.</Text>

        <Heading size="md">Our Use of Cookies</Heading>
        <Text>Essential Cookies: We use only the essential cookies required for the functioning of our service. These cookies are necessary for the basic operations of our website and cannot be switched off in our systems. They are typically set in response to your actions which amount to a request for services, such as setting your privacy preferences or filling in forms.</Text>
        <Text>No Marketing or Analytics Cookies: In line with our commitment to your privacy, we do not use any marketing or analytics cookies. We believe in providing a service that respects your right to privacy and does not track your activity beyond our website for any advertising or analytical purposes.</Text>

        <Heading size="md">Managing Cookies</Heading>

        <Text>While essential cookies cannot be turned off, you have control over your browser settings to accept or reject other types of cookies.</Text>
        <Text>You can set your browser to block or alert you about these cookies, but some parts of the site may not work as a result.
          Your Consent:By using our service, you consent to our use of essential cookies for the purposes outlined in this policy.</Text>

        <Text>Changes to Our Cookie Policy:
          We may update this policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons.</Text>

        <Text>Contact Us: If you have any questions about our use of cookies, please contact us at info@lawly.cz.</Text>


      </Stack>
    </Container>
  );
}

export default CookiesPolicyPage;
import React, { useEffect } from 'react';
import {
  Heading,
  Text,
  Stack,
  Container,
  useColorModeValue,
} from '@chakra-ui/react'
import { useParams, useNavigate } from "react-router-dom";


const PaymentPage: React.FC = () => {
  const { status } = useParams();
  const payment = status === 'success' ? true : false;

  const statusTextTitle= payment ? 'Payment was successful' : 'Payment failed';
  const statusTextHeader = payment ? 'You will be redirected to dashboard soon' : 'Something went wrong, please contact us at info@lawly.cz';

  const navigate = useNavigate();

  useEffect(() => {
    if (status === 'success') {
      const timer = setTimeout(() => {
        navigate('/dashboard');
      }, 10000);  // 10 seconds

      // Clean up the timer when the component is unmounted or if the effect is re-run
      return () => clearTimeout(timer);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <Container maxW={'5xl'} py={12}>
      <Stack spacing={4}>
        <Text
          textTransform={'uppercase'}
          color={'blue.400'}
          fontWeight={600}
          fontSize={'sm'}
          bg={useColorModeValue('blue.50', 'blue.900')}
          p={2}
          alignSelf={'flex-start'}
          rounded={'md'}>
          Payment Information
        </Text>
        <Heading>{statusTextTitle}</Heading>
        <Text color={'gray.500'} fontSize={'lg'}>
        {statusTextHeader}
        </Text>

        <Text>

        </Text>

      </Stack>
    </Container>
  );
}

export default PaymentPage;


const toastAlert= async(func: any, textToast: string, toast:any, successToast: boolean) => {
    try {
        const response = await func();
        if(response.code === 'ECONNABORTED'){ }
        if(successToast){
            toast({
                position: 'top',
                title: response.message ?? textToast,
                status: response.success ? 'success' : 'warning',
                duration: 5000,
                isClosable: true,
            })
        }
        return response
    } catch (error) {
        toast({
            position: 'top',
            title: textToast,
            description: "Unexpected error",
            status: 'error',
            duration: 5000,
            isClosable: true,
        })
    }
}

export default toastAlert;


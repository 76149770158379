import React from 'react';
import {
  Heading,
  Text,
  Stack,
  Container,
  useColorModeValue,
  Divider,
} from '@chakra-ui/react'

import pavolTravnikImg from '../assets/images/pavol-travnik.jpg';
import SocialProfileWithImage from '../components/SocialProfileWithImageComponent';

const AboutUsPage: React.FC = () => {

  return (
    <Container maxW={'5xl'} py={12}>
      <Stack spacing={4}>
        <Text
          textTransform={'uppercase'}
          color={'blue.400'}
          fontWeight={600}
          fontSize={'sm'}
          bg={useColorModeValue('blue.50', 'blue.900')}
          p={2}
          alignSelf={'flex-start'}
          rounded={'md'}>
          General Information
        </Text>
        <Heading>Status</Heading>
        <Text color={'gray.500'} fontSize={'lg'}>
          Last Updated: 2023-11-16
        </Text>



        <Heading size="md">Constitutional Court of the Czech Republic CECC</Heading>
        <Text>Complete</Text>
        <Divider />

        <Heading size="md">The Supreme Court of the Czech Republic</Heading>
        <Text>Complete</Text>
        <Divider />


        <Heading size="md">The Supreme Administrative Court of the Czech Republic</Heading>
        <Text>In progress</Text>
        <Divider />


        <Heading size="md">District and Appeal Courts of the Czech Republic</Heading>
        <Text>Complete</Text>


      </Stack>
    </Container>
  );
}

export default AboutUsPage;
import React, { useRef, useState } from 'react';
import { requestLogin } from '../services/authService';
import {
    Box,
    Button,
    Heading,
    Input,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react'
import toastAlert from './ToastComponent';
import { useNavigate } from "react-router-dom";

const LoginForm: React.FC = () => {
    const navigate = useNavigate();
    const toast = useToast()
    const usernameRef = useRef<HTMLInputElement>(null);
    const [sent, setSent] = useState(false);

    const handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const response = await toastAlert(() => requestLogin(usernameRef.current?.value ?? ''), 'Login failed', toast, true)
            response.code === "ERR_NETWORK" && navigate("/service-down")
            response.success && setSent(true)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Box maxW="md" mx="auto">
            <Stack
                spacing={4}
                w={'full'}
                maxW={'md'}
                rounded={'xl'}
                p={6}
                my={12}>
                <></>
                {!sent ? <>
                    <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
                        Sign in with email
                    </Heading>
                    <Text
                        fontSize={{ base: 'sm', sm: 'md' }}>
                        Enter the email address associated with your account, and we’ll send a magic link to your inbox.
                    </Text>
                    <form onSubmit={handleFormSubmit}>
                        <Stack spacing={4}>
                            <Input ref={usernameRef}
                                id="code"
                                type="text" autoComplete="email" />
                            <Button type="submit" size="lg" fontSize="md">
                                Continue
                            </Button>
                        </Stack>
                    </form></> : <>
                    <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
                        Link has been sent
                    </Heading>
                    <Text
                        fontSize={{ base: 'sm', sm: 'md' }}>
                        Email has been sent to your email address. Please check your inbox and login via the link.
                    </Text>
                    <Text
                        fontSize={{ base: 'xs', sm: 'sm' }} color={"GrayText"}>
                        Passwordless login enhances security by eliminating the risks of password breaches and phishing attacks, while providing a seamless and user-friendly authentication experience.
                    </Text>
                    <Stack spacing={4}>
                        <Button type="submit" size="lg" fontSize="md" onClick={() => setSent(false)}>
                            Try again
                        </Button>
                    </Stack>
                </>
                }
            </Stack>
        </Box>
    );
}

export default LoginForm;


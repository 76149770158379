import React from 'react';
import {
  Heading,
  Text,
  Stack,
  Container,
  useColorModeValue,
} from '@chakra-ui/react'

const PrivacyPolicyPage: React.FC = () => {

  return (
    <Container maxW={'5xl'} py={12}>
      <Stack spacing={4}>
        <Text
          textTransform={'uppercase'}
          color={'blue.400'}
          fontWeight={600}
          fontSize={'sm'}
          bg={useColorModeValue('blue.50', 'blue.900')}
          p={2}
          alignSelf={'flex-start'}
          rounded={'md'}>
          Legal Information
        </Text>
        <Heading>Privacy Policy</Heading>
        <Text color={'gray.500'} fontSize={'lg'}>
          Last Updated: 2023-11-16
        </Text>

        <Text mb={3}>
          Welcome to lawly.cz and soutnijednani.cz. We are committed to protecting the privacy and security of our users. This privacy policy outlines how we collect, use, and safeguard your information when you use our service.
        </Text>

        <Heading size="md" mb={2}>Types of Accounts:</Heading>
        <Text mb={3}>
          1. <strong>Free Accounts:</strong> For users of our free service tier, we require only your email address to facilitate passwordless login. This simplifies your access while maintaining security.
        </Text>
        <Text mb={3}>
          2. <strong>Paid Accounts:</strong> For our paid service users, we uphold a strict policy of not collecting any additional user data. Your privacy is our top priority.
        </Text>

        <Heading size="md" mb={2}>Data Collection and Usage:</Heading>
        <Text mb={3}>
          - <strong>Email Information:</strong> We store your email address to enable passwordless login.
        </Text>
        <Text mb={3}>
          - <strong>No Additional Data for Paid Accounts:</strong> We do not collect any personal or usage data from our paid account users.
        </Text>
        <Text mb={3}>
          - <strong>Traffic Analysis for Free Accounts:</strong> To enhance the user experience and improve our service, we collect traffic data from our free tier users. This involves analyzing usage patterns and site interactions.
        </Text>

        <Heading size="md" mb={2}>Privacy First Approach:</Heading>
        <Text mb={3}>
          - We value the privacy of our clients and are dedicated to protecting your data. We do not sell, trade, or rent your personal identification information to others.
        </Text>
        <Text mb={3}>
          - Our privacy-first approach ensures that any data collection is minimal and strictly for the purpose of improving service quality and user experience.
        </Text>

        <Heading size="md" mb={2}>Consent and Control:</Heading>
        <Text mb={3}>
          - By using our service, you consent to our privacy policy.
        </Text>
        <Text mb={3}>
          - Users have the right to access their information, correct any inaccuracies, and opt-out of non-essential data collection where applicable.
        </Text>

        <Heading size="md" mb={2}>Updates to Our Privacy Policy:</Heading>
        <Text mb={3}>
          - We reserve the right to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage users to frequently check this page for any changes.
        </Text>

        <Heading size="md" mb={2}>Contact Us:</Heading>
        <Text mb={3}>
          - If you have any questions about our privacy practices or this policy, please contact us at info@lawly.cz.
        </Text>


        <Heading size="md" mb={2}>Last edit of Privacy Policy</Heading>
        <Text mb={3}>
          - 2023-11-16: Creation of Privacy Policy
        </Text>

      </Stack>
    </Container>
  );
}

export default PrivacyPolicyPage;
import React from 'react';
import { useParams } from "react-router-dom";
import LoginPasswordless from '../components/LoginPasswordless';

const LoginPasswordlessPage: React.FC = () => {
  const { token } = useParams();

  return (
     <div id="app">
        <LoginPasswordless token={token}/>
    </div> 
);
}

export default LoginPasswordlessPage;

import React from 'react';
import LoginForm from '../components/LoginForm';

const LoginPage: React.FC = () => {

  return (
     <div id="app">
        <LoginForm />
    </div> 
);
}

export default LoginPage;
import React from 'react';
import Search from '../components/Search';

const SearchPage: React.FC = () => {

  return (
     <div id="app">
        <Search />
    </div> 
);
}

export default SearchPage;
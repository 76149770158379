import React from 'react';
import {
  Heading,
  Text,
  Stack,
  Container,
  useColorModeValue,
} from '@chakra-ui/react'

const ReturnPaymentPage: React.FC = () => {

  return (
    <Container maxW={'5xl'} py={12}>
      <Stack spacing={4}>
        <Text
          textTransform={'uppercase'}
          color={'blue.400'}
          fontWeight={600}
          fontSize={'sm'}
          bg={useColorModeValue('blue.50', 'blue.900')}
          p={2}
          alignSelf={'flex-start'}
          rounded={'md'}>
          Legal Information
        </Text>
        <Heading>Return Payment Policy</Heading>
        <Text color={'gray.500'} fontSize={'lg'}>
          Last Updated: 2023-11-16
        </Text>



        <Text>At Determinant, s.r.o., we pride ourselves on delivering exceptional service with our search engine and prediction tool. We understand the importance of meeting your expectations, and in instances where our service doesn't fully meet your needs, our return policy is here to ensure your satisfaction.</Text>


        <Heading size="md">Initiating a Service Return</Heading>

        <Text>Email Us: If you're not satisfied with our service, please email us at [your-email@example.com] within 30 days of your subscription or purchase. While you're not required to explain the issue, we highly appreciate any feedback that can help us improve.</Text>
        <Text>Quick Review: Our team will review your return request promptly and will guide you through the process.</Text>

        <Heading size="md">Our 30-Day Guarantee</Heading>

        <Text>Eligibility Period: You have 30 days from the initial purchase or subscription date to request a return.</Text>
        <Text>Refund Process: Once your return is approved, we will initiate a refund to your original payment method. Please allow a few days for the transaction to reflect in your account, depending on your bank or payment service provider.</Text>

        <Heading size="md">Additional Information:</Heading>

        <Text>Feedback: While not mandatory, your feedback is invaluable to us for improving our services.</Text>
        <Text><Text>Customer Support: If you have any questions or need assistance with the return process, feel free to reach out. Our customer support team is here to help.</Text>
          Contact Us: For any inquiries or assistance, please contact us at info@lawly.cz. We're committed to ensuring your satisfaction with our search engine and prediction tool services.</Text>


      </Stack>
    </Container>
  );
}

export default ReturnPaymentPage;
import React, { useEffect } from 'react';
import {
  Heading,
  Text,
  Stack,
  Container,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'


const ScientificPaperPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Scientific Paper';
  }, []);
  return (
    <Container maxW={'5xl'} py={12}>
      <Stack spacing={4}>
        <Text
          textTransform={'uppercase'}
          color={'blue.400'}
          fontWeight={600}
          fontSize={'sm'}
          bg={useColorModeValue('blue.50', 'blue.900')}
          p={2}
          alignSelf={'flex-start'}
          rounded={'md'}>
          Scientific Information          </Text>
        <Heading>Revolutionizing Legal Decisions: The Case Law Prediction Tool</Heading>
        <Text color={'gray.500'} fontSize={'lg'}>
        A Machine Learning Approach to Predicting Case Law Outcomes: An Exploration of Company Determinant's Neural Network Model
        </Text>
        <Text>The complexity of legal decisions has long been a subject of intense scrutiny. Predicting case law outcomes can offer professionals a strategic edge, yet the multifaceted nature of legal disputes makes it challenging. This paper delves into the machine learning model developed by Company Determinant, s.r.o, trained on over 100,000 court decisions, to offer a predictive tool for legal professionals.</Text>
        <Text>Why is this important? The legal realm can often be murky with numerous factors affecting decisions. A tool that employs neural networks and vast datasets can offer clarity and predictability in a world filled with uncertainties.</Text>
        <Text>Here's a snapshot of the tool's performance</Text>
        <List spacing={3}>
          <ListItem>
            <ListIcon as={CheckIcon} color='green.500' />
            ROC AUC Score: 0.9108
          </ListItem>
          <ListItem>
            <ListIcon as={CheckIcon} color='green.500' />
            Accuracy: 0.8353
          </ListItem>
          <ListItem>
            <ListIcon as={CheckIcon} color='green.500' />
            Precision: 0.9370
          </ListItem>
          <ListItem>
            <ListIcon as={CheckIcon} color='green.500' />
            Recall: 0.8520
          </ListItem>
          <ListItem>
            <ListIcon as={CheckIcon} color='green.500' />
            F1 Score: 0.8925
          </ListItem>

        </List>
        <Text>With such impressive metrics, professionals can make informed decisions on whether to appeal a ruling or not. And in the rare instance the prediction is off? Company Determinant ensures confidence by offering a full return policy.</Text>
        <Text>However, while the tool offers valuable insights, it's pivotal to remember the complexity of human relations and individual case nuances. Technology is here to assist, not replace.</Text>

      </Stack>
    </Container>
  );
}

export default ScientificPaperPage;
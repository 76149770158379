import React, { useRef, useState, useEffect } from 'react';
import parse from 'html-react-parser';

import { statusLogin } from '../services/authService';
import toastAlert from './ToastComponent';
import { SoudAvatarComponent } from './SoudAvatarComponent';


import { requestSearch } from '../services/searchService';
import {
    Avatar,
    Center, CircularProgress,
    Box,
    Card, CardHeader, CardBody,
    Checkbox,
    Divider,
    Container,
    Button,
    Heading,
    Flex,
    HStack,
    Input,
    Stack,
    Text,
    useToast,
    Link,
    Tag,
    TagLabel,
    Spacer,
} from '@chakra-ui/react'
// import toastAlert from './ToastComponent';
import { useNavigate } from "react-router-dom";
// import { ExternalLinkIcon } from '@chakra-ui/icons'


const SearchForm: React.FC = () => {
    const toast = useToast()
    const [loadingPage, setLoadingPage] = useState(true);
    const [userID, setUser] = useState('' as string | null);
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        document.title = "Vyhledávání soudního rozhodnutí"
        const checkAuthStatus = async () => {
            try {
                setLoadingPage(true);
                const response = await toastAlert(() => statusLogin(), 'Authentication failed', toast, false)
                if (response.code === "ERR_NETWORK") {
                    logout("/service-down")
                    return
                }
                if (response.data.success) {
                    setLoggedIn(true);
                    setUser(response.data.user.userId);
                    setLoadingPage(false);
                } else {
                    logout()
                }
            } catch (error) {
                logout()
            }
        };

        checkAuthStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logout = async (path: string = "/signin") => {
        setLoggedIn(false);
        setUser(null);
        navigate(path);
        setLoadingPage(false);
    };

    function SearchResult({ highlightedText }: { highlightedText: string }) {
        return <div>{parse(highlightedText)}</div>;
    }
    const navigate = useNavigate();
    // const toast = useToast()
    const [firstInput, setFirstInput] = useState(false);
    const usernameRef = useRef<HTMLInputElement>(null);
    const usCheckboxRef = useRef<HTMLInputElement>(null);
    const nsCheckboxRef = useRef<HTMLInputElement>(null);
    const nssCheckboxRef = useRef<HTMLInputElement>(null);
    const ksCheckboxRef = useRef<HTMLInputElement>(null);
    const [sent, setSent] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    // const [searchResultsAvg, setSearchAvg] = useState([]);

    const handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            if (usernameRef.current?.value === '') return
            setSent(true)
            setFirstInput(true)
            setSearchResults([])
            const response = await requestSearch({ query: usernameRef.current?.value ?? '', indices: { us: !!usCheckboxRef.current?.checked, ns: !!nsCheckboxRef.current?.checked, ks: !!ksCheckboxRef.current?.checked } })
            response.code === "ERR_NETWORK" && navigate("/service-down")
            response.success && setSent(true)
            setSearchResults(response.data)
            // setSearchAvg(response.data.result_avg)
        } catch (error) {
            console.log(error)
        } finally {
            setSent(false)
        }
    }
    function formatDate(isoDate: string) {
        try {
            const date = new Date(isoDate);
            const parts: string[] = date.toLocaleDateString().split('/');
            const year = parts[2];
            const dayMonth = parts.slice(0, 2).join('/');
            return <>{dayMonth}/<strong>{year}</strong></>;
        } catch (error) {
            return ""
        }
    }


    function convertIndex(index: string) {
        if (index === 'nalus_usoud_cz_metadata') return 'us'
        if (index === 'nsoud_cz_metadata') return 'ns'
        if (index === 'nssoud_cz_metadata') return 'nss'
        if (index === 'rozhodnuti_justice_cz_os_metadata') return 'ks'
        else return index
    }

    return (
        <>
            {loadingPage && <Center><CircularProgress isIndeterminate color="green.300" /></Center>}

            {!loadingPage && loggedIn && (
                <Container maxW={'5xl'} py={12}>
                    <Stack
                        // spacing={4}
                        w={'full'}
                        // maxW={'md'}
                        rounded={'xl'}
                        p={6}
                    // my={12}
                    >
                        <form onSubmit={handleFormSubmit}>
                            <Stack spacing={4}>
                                <HStack spacing={4}>
                                    <Input ref={usernameRef}
                                        id="code"
                                        type="text"
                                        autoComplete="off"
                                        maxLength={300}
                                    />

                                    <Button type="submit">
                                        Search
                                    </Button>

                                </HStack>
                                <Stack spacing={5} direction='row'>
                                    <Checkbox colorScheme='blue' ref={usCheckboxRef} defaultChecked>ÚS</Checkbox>
                                    <Checkbox colorScheme='blue' ref={nsCheckboxRef} defaultChecked>NS</Checkbox>
                                    <Checkbox colorScheme='blue' ref={nssCheckboxRef} defaultChecked>NSS</Checkbox>
                                    <Checkbox colorScheme='blue' ref={ksCheckboxRef} defaultChecked>KS a OS</Checkbox>
                                </Stack>

                            </Stack>
                        </form>
                        {sent && <Text>Searching...</Text>}

                        <div>
                            {searchResults && Array.isArray(searchResults) && searchResults.length > 0 ? (
                                <>
                                    {searchResults.map((item: any, index) => (
                                        <Box
                                            p={4} // Apply padding to all sides
                                        // pb={4} // Additional bottom padding
                                        // bg="white" // Background color
                                        // boxShadow="sm" // Shadow
                                        // borderRadius="md" // Rounded corners
                                        >
                                            <Card>
                                                <CardHeader>
                                                    <Flex
                                                        direction={{ base: "column", md: "row" }}
                                                        justifyContent="space-between"
                                                        width="100%"
                                                    >
                                                        <Box w="100%" p={2} pt={4}>
                                                            <Link href={`/document/${convertIndex(item._index)}/${item._id}`} isExternal><Heading size='md'>{item._source.spzn}</Heading></Link>
                                                        </Box>
                                                        <Box w="100%" alignSelf="center" p={2}>
                                                            <SoudAvatarComponent soud={item._source.soud} />
                                                        </Box>
                                                        <Box w="100%" p={2}>
                                                            <Tag size='lg' colorScheme='gray' borderRadius='full' variant='outline'>
                                                                <TagLabel>{formatDate(item._source.date_iso)}</TagLabel>
                                                            </Tag>
                                                        </Box>
                                                    </Flex>

                                                </CardHeader>
                                                <CardBody>
                                                    {/* <Text>View a summary of all your customers over the last month.</Text> */}
                                                    <>
                                                        {item.highlight.czech_text.map((highlight: any, index_highlight: any) => (
                                                            <>
                                                                <SearchResult key={index_highlight} highlightedText={highlight}></SearchResult>
                                                            </>
                                                        ))}
                                                    </>
                                                </CardBody>
                                            </Card>

                                            <Divider />

                                        </Box>
                                    ))}
                                </>
                            ) : firstInput && (
                                <p>No results found.</p>
                            )}
                        </div>


                    </Stack>
                </Container>
            )}

        </>

    );
}

export default SearchForm;


import React from 'react';
import { useParams } from "react-router-dom";
import Display from '../components/Display';

const DisplayPage: React.FC = () => {
  const { index, id } = useParams();

  return (
     <div id="app">
        <Display index={index} id={id} />
    </div> 
);
}

export default DisplayPage;
import React, { useEffect } from 'react';
// import Testimonials from '../components/Testimonials';
import Features from '../components/Features';

const MainPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Soudní rozhodnutí';
  }, []);

  return (
    <div id="main-page">
      <Features />
      {/* <Testimonials /> */}
    </div>
  );
}

export default MainPage;
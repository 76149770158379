import React, { useEffect } from 'react';
import Dashboard from '../components/Dashboard';

const DashboardPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Dahboard';
  }, []);

  return (
    <div id="main-page">
      <Dashboard />
    </div>
  );
}

export default DashboardPage;

import React, { useRef, useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { statusLogin } from '../services/authService';
import toastAlert from './ToastComponent';
import { requestSearch, requestDocument, requestSimilarity } from '../services/searchService';
import {
    Box,
    Card, CardHeader, CardBody,
    Center,
    CircularProgress,
    Divider,
    Container,
    Button,
    Flex,
    Heading,
    HStack,
    Input,
    Stack,
    Text,
    useToast,
    Link,
    Tag,
    Spacer,
    Wrap, WrapItem,
    // useColorModeValue,
} from '@chakra-ui/react'
// import toastAlert from './ToastComponent';
import { useNavigate } from "react-router-dom";
import { log } from 'console';
// import { ExternalLinkIcon } from '@chakra-ui/icons'
interface DisplayProps { index: string | undefined, id: string | undefined }

const Display: React.FC<DisplayProps> = ({ index, id }: DisplayProps) => {
    const toast = useToast()
    const [loadingPage, setLoadingPage] = useState(true);
    const [userID, setUser] = useState('' as string | null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [doc, setDocument] = useState({} as any);
    const [similarity, setSimilarity] = useState({} as any);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                setLoadingPage(true);
                const response = await toastAlert(() => statusLogin(), 'Authentication failed', toast, false)
                if (response.code === "ERR_NETWORK") {
                    logout("/service-down")
                    return
                }
                if (response.data.success) {
                    setLoggedIn(true);
                    setUser(response.data.user.userId);
                    getDocument();
                    setLoadingPage(false);
                } else {
                    logout()
                }
            } catch (error) {
                logout()
            }
        };

        const getDocument = async () => {
            if (!index || !id) return
            const response = await requestDocument({ index, id })
            if (response.success) {
                setDocument(response)
                document.title = response.document?.spzn ?? response.document?.['Jednací číslo:'] ?? "Soudní rozhodnutí";
            }
            const responseSimilarity = await requestSimilarity({ index, id })
            if (responseSimilarity.success) {
                setSimilarity(responseSimilarity)
            }
        };

        checkAuthStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logout = async (path: string = "/signin") => {
        setLoggedIn(false);
        setUser(null);
        navigate(path);
        setLoadingPage(false);
    };

    const navigate = useNavigate();

    console.log(similarity)



    return (
        <>
            <Container maxW={'5xl'} py={12}>
                {loadingPage && <Center><CircularProgress isIndeterminate color="green.300" /></Center>}

                {!loadingPage && doc.success && <Stack spacing={4}>
                    <Text
                        textTransform={'uppercase'}
                        color={'blue.400'}
                        fontWeight={600}
                        fontSize={'sm'}
                        // bg={useColorModeValue('blue.50', 'blue.900')}
                        p={2}
                        alignSelf={'flex-start'}
                        rounded={'md'}>
                        {doc.document?.soud ?? doc.document?.['Soud:']}</Text>
                    <Heading>{doc.document?.spzn ?? doc.document?.['Jednací číslo:']}</Heading>

                    <>
                        {doc.content.content.map((paragraph: string, ind: any) => (
                            <>
                                <Text key={ind}><div dangerouslySetInnerHTML={{ __html: paragraph }} /></Text>
                            </>
                        ))}
                    </>
                    <Divider  p={4} />

                    <Heading>AI generovaná podobnost rozhodnutí <Link href="https://pytorch.org/docs/stable/generated/torch.nn.CosineSimilarity.html" isExternal>COSINESIMILARITY</Link></Heading>

                    <Flex
                        direction={{ base: "column", md: "row" }}
                        width="100%"
                        align="center"
                        justify="center"
                    >
                        <Box w="100%" p={4}>
                            {similarity.us_documents && Array.isArray(similarity.us_documents) && similarity.us_documents.length > 0 && (
                                <>
                                    <Text fontSize='3xl'>ÚS</Text>
                                    {similarity.us_documents.map((doc: any, ind: any) => (
                                        <>
                                            <Link href={`/document/us/${doc._id}`} isExternal><Text size='md'>{doc.spzn}</Text></Link>

                                        </>
                                    ))}
                                </>
                            )}
                        </Box>
                        <Box w="100%" p={4}>
                            {similarity.ns_documents && Array.isArray(similarity.ns_documents) && similarity.ns_documents.length > 0 && (
                                <>
                                    <Text fontSize='3xl'>NS</Text>
                                    {similarity.ns_documents.map((doc: any, ind: any) => (
                                        <>
                                            <Link href={`/document/ns/${doc._id}`} isExternal><Text size='md'>{doc.spzn}</Text></Link>

                                        </>
                                    ))}
                                </>
                            )}
                        </Box>
                        <Box w="100%" p={4}>
                            {similarity.ks_documents && Array.isArray(similarity.ks_documents) && similarity.ks_documents.length > 0 && (
                                <>
                                    <Text fontSize='3xl'>KS a OS</Text>
                                    {similarity.ks_documents.map((doc: any, ind: any) => (
                                        <>
                                            <Link href={`/document/ks/${doc._id}`} isExternal><Text size='md'>{doc?.['Jednací číslo:']}</Text></Link>

                                        </>
                                    ))}
                                </>
                            )}
                        </Box>
                    </Flex>
                </Stack>}
            </Container>

        </>

    );
}

export default Display;


'use client'
import packageInfo from '../../package.json'; // Adjust the path according to your file structure

// import { ReactNode } from 'react'
import {
  Box,
  Container,
  Stack,
  SimpleGrid,
  Text,
  // VisuallyHidden,
  // chakra,
  useColorModeValue,
} from '@chakra-ui/react'
// import { FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa'

// import { AppStoreBadge } from '#/components/AppStoreBadge'
// import { PlayStoreBadge } from '#/components/PlayStoreBadge'

// const ListHeader = ({ children }: { children: ReactNode }) => {
//   return (
//     <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
//       {children}
//     </Text>
//   )
// }

// const SocialButton = ({
//   children,
//   label,
//   href,
// }: {
//   children: ReactNode
//   label: string
//   href: string
// }) => {
//   return (
//     <chakra.button
//       bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
//       rounded={'full'}
//       w={8}
//       h={8}
//       cursor={'pointer'}
//       as={'a'}
//       href={href}
//       display={'inline-flex'}
//       alignItems={'center'}
//       justifyContent={'center'}
//       transition={'background 0.3s ease'}
//       _hover={{
//         bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
//       }}>
//       <VisuallyHidden>{label}</VisuallyHidden>
//       {children}
//     </chakra.button>
//   )
// }

export default function LargeWithAppLinksAndSocial() {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
          <Stack align={'flex-start'}>
            {/* <ListHeader>Company</ListHeader> */}
            <Box as="a" href={'/about-us'}>
              About Us
            </Box>
            {/* <Box as="a" href={'#'}>
              Blog
            </Box> */}
            {/* <Box as="a" href={'#'}>
              Careers
            </Box> */}
            <Box as="a" href={'/contact-us'}>
              Contact Us
            </Box>
          </Stack>

          <Stack align={'flex-start'}>
            {/* <ListHeader>Support</ListHeader> */}
            <Box as="a" href={'/return-payment'}>
              Return Payment
            </Box>
            <Box as="a" href={'/credits-payment'}>
              Credits & Payment
            </Box>
          </Stack>

          <Stack align={'flex-start'}>
            {/* <ListHeader>Legal</ListHeader> */}
            <Box as="a" href={'/cookies-policy'}>
              Cookies Policy
            </Box>
            <Box as="a" href={'/privacy-policy'}>
              Privacy Policy
            </Box>
            <Box as="a" href={'/terms-of-service'}>
              Terms of Service
            </Box>
          </Stack>

          <Stack align={'flex-start'}>
            {/* <ListHeader>Legal</ListHeader> */}
            <Box as="a" href={'/status'}>
              Data Status
            </Box>
          </Stack>


          {/* <Stack align={'flex-start'}>
            <ListHeader>Install App</ListHeader>
           <AppStoreBadge />
            <PlayStoreBadge /> 
          </Stack> */}
        </SimpleGrid>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.700')}>
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ md: 'space-between' }}
          align={{ md: 'center' }}>
          <Text>© 2023 Determinant, s.r.o. All rights reserved v{packageInfo.version}</Text>
          {/* <Stack direction={'row'} spacing={6}>
            <SocialButton label={'Twitter'} href={'#'}>
              <FaTwitter />
            </SocialButton>
            <SocialButton label={'YouTube'} href={'#'}>
              <FaYoutube />
            </SocialButton>
            <SocialButton label={'Instagram'} href={'#'}>
              <FaInstagram />
            </SocialButton>
          </Stack> */}
        </Container>
      </Box>
    </Box>
  )
}
import React from 'react';
import {
  AlertIcon,
  Alert,
  Box,
  Heading,
  Text,
  Stack,
  Container,
  useColorModeValue,
} from '@chakra-ui/react'

const CreditsPaymentPage: React.FC = () => {

  return (
    <Container maxW={'5xl'} py={12}>
      <Stack spacing={4}>
        <Text
          textTransform={'uppercase'}
          color={'blue.400'}
          fontWeight={600}
          fontSize={'sm'}
          bg={useColorModeValue('blue.50', 'blue.900')}
          p={2}
          alignSelf={'flex-start'}
          rounded={'md'}>
          Legal Information
        </Text>
        <Heading>Cretits and Payment</Heading>
        <Text color={'gray.500'} fontSize={'lg'}>
          Last Updated: 2023-09-01
        </Text>

        <Box>
          <Alert status="info" borderRadius={5}>
            <AlertIcon />
            <Text>
              <strong>Alpha Testing Phase:</strong> Credits and payments are currently in test mode. All accounts have the opportunity to try our service for free during this period. We appreciate your participation and feedback!
            </Text>
          </Alert>

          <Heading size="md" mb={4} mt={10}>Try Our Service for Free!</Heading>
          <Text>
            As part of our alpha testing phase, we're excited to offer you full access to Lawly at no cost. This is a limited time opportunity to experience our service and help us improve.
          </Text>
          <Text mt={3}>
            Please note that during this phase, all transactions and credits are simulated and purely for testing purposes. No real transactions will occur, and no payment information is required.
          </Text>
        </Box>

      </Stack>
    </Container>
  );
}

export default CreditsPaymentPage;
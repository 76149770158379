import React, { useEffect} from 'react';
import toastAlert from '../components/ToastComponent';
import { logout } from '../services/authService';
import { useNavigate } from "react-router-dom";
import { useToast } from '@chakra-ui/react'

const LogoutPage: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast()

  useEffect(() => {

    const logoutAction = async () => {
      try {
        const response = await toastAlert(() => logout(), 'Authentication failed', toast, false)
        if (response.code === "ERR_NETWORK") {
          navigate("/service-down");
          return
        }
        if (response.data.success) {
          navigate("/");
        }
      } catch (error) {
        console.log(error)
      }
    };

    logoutAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
     <div id="app">

    </div> 
);
}

export default LogoutPage;
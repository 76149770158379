import React, { useEffect } from 'react';
import { BASE_URL_BACKEND } from '../config';
import {
    // Box,
    Button,
    Center,
    CircularProgress,
    Heading,
    Text,
    VStack
} from '@chakra-ui/react';
interface LoginPasswordlessProps { token: string | undefined }

const LoginPasswordless: React.FC<LoginPasswordlessProps> = ({ token }) => {

    useEffect(() => {
        const timer = setTimeout(() => {
            window.location.href = `${BASE_URL_BACKEND}/validate-login/?token=${token}`
        }, 4000); // 4 seconds timer

        return () => clearTimeout(timer);
    }, [token]);

    const handleManualRedirect = () => {
        window.location.href = `${BASE_URL_BACKEND}/validate-login/?token=${token}`
    };

    return (
        <Center>
            <VStack spacing={5} p={5} bg="white" shadow="md" rounded="lg">
                <CircularProgress isIndeterminate color="green.300" />
                <Heading size="md">Redirecting...</Heading>
                <Text>Hold tight, we are logging you to the application.</Text>
                <Button type="submit" size="lg" fontSize="md" onClick={handleManualRedirect}>
                    Redirect Now
                </Button>
            </VStack>
        </Center>
    );
};

export default LoginPasswordless;
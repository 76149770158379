import React from 'react';
import { useRouteError } from "react-router-dom";
import {
  Heading,
  Text,
  Stack,
  Container,
  useColorModeValue,
} from '@chakra-ui/react'


const ErrorPage: React.FC = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <Container maxW={'5xl'} py={12}>
      <Stack spacing={4}>
        <Text
          textTransform={'uppercase'}
          color={'red.400'}
          fontWeight={600}
          fontSize={'sm'}
          bg={useColorModeValue('blue.50', 'blue.900')}
          p={2}
          alignSelf={'flex-start'}
          rounded={'md'}>
          Error message
        </Text>
        <Heading>Error</Heading>
        <Text color={'gray.500'} fontSize={'lg'}>
        Page was not found
        </Text>

      </Stack>
    </Container>
  );
}

export default ErrorPage;
import React from 'react';
import {
  Heading,
  Text,
  Stack,
  Container,
  useColorModeValue,
} from '@chakra-ui/react'

const ContactUsPage: React.FC = () => {

  return (
    <Container maxW={'5xl'} py={12}>
      <Stack spacing={4}>
        <Text
          textTransform={'uppercase'}
          color={'blue.400'}
          fontWeight={600}
          fontSize={'sm'}
          bg={useColorModeValue('blue.50', 'blue.900')}
          p={2}
          alignSelf={'flex-start'}
          rounded={'md'}>
          General Information
        </Text>
        <Heading>Contacts</Heading>
        <Text color={'gray.500'} fontSize={'lg'}>
          Last Updated: 2023-11-14
        </Text>
        <Text>
          Email: info@lawly.cz
        </Text>
        
        <Text>Mgr. Pavol Trávnik</Text>
        <Text>Determinant s.r.o.</Text>
        <Text> Holečkova 836/69, Smíchov, 150 00 Praha 5</Text>
        <Text>Spisová značka: C 385077 vedená u Městského soudu v Praze</Text>


      </Stack>
    </Container>
  );
}

export default ContactUsPage;
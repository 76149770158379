import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { extendTheme, ChakraProvider } from '@chakra-ui/react'
// import { AppProvider } from './context/context';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import {
  RouterProvider,
} from "react-router-dom";
import { Container, VStack, Flex } from '@chakra-ui/react';
import router from './router';

const colors = {
  brand: {
    900: '#000',
    800: '#000',
    700: '#000',
  },
  soud: {
    ustavni: '#FED7D7',
    spravni: '#BEE3F8',
    nejvyssi: '#A0AEC0',
    others: '#EDF2F7',
  },
}
const theme = extendTheme({ colors })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Flex direction="column" minH="100vh">
      <NavBar />
        <VStack spacing={0} flex="1">
        <Container maxW="6xl">
            <RouterProvider router={router} />
        </Container>
        </VStack>
        <Footer />
      </Flex>
      {/* </AppProvider> */}
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Importing Axios to make HTTP requests
import axios from 'axios';
import { BASE_URL_BACKEND } from '../config';
axios.defaults.withCredentials = true;
axios.defaults.timeout = 5000;  // 5 seconds timeout for all requests

// Function to get user's credit
export const getUserCredit = async () => {
  try {
    // Making a GET request to the '/credit' endpoint to get the user's credit
    // The user's ID is sent as a parameter
    const response = await axios.get(`${BASE_URL_BACKEND}/user-credit`);

    // Returning the data received from the server
    return response.data;
  } catch (error) {
    // Throwing the error if the request fails
    throw error;
  }
};

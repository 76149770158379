'use client'

import {
  Heading,
  Avatar,
  Box,
  Center,
  Image,
  Flex,
  Text,
  Stack,
  Button,
  useColorModeValue,
  HStack,
  SimpleGrid,
  Link,
} from '@chakra-ui/react'
import pavolTravnikImg from '../assets/images/pavol-travnik.jpg';

function SocialProfileWithImage() {
  return (


    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
      <Stack spacing={3}>
        <Box p={6}>
          <Flex justify={'center'} p={4}>
            <Avatar
              h={'220px'}
              // size={'full'}
              w={'220px'}
              src={
                pavolTravnikImg
              }
              css={{
                border: '2px solid white',
              }}
            />
          </Flex>
          <Box>
            <Stack spacing={0} align={'center'} mb={0}>
              <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
                Pavol Trávnik
              </Heading>
              <Text color={'gray.500'}>Full Stack Developer</Text>
              <Text color={'gray.500'}>Lawyer & Arbitrator</Text>
              <Link href={`https://www.linkedin.com/in/pavol-travnik/`} isExternal>
                <Button
                  w={'full'}
                  mt={8}
                  bg={useColorModeValue('#151f21', 'gray.900')}
                  color={'white'}
                  rounded={'md'}
                  _hover={{
                    transform: 'translateY(-2px)',
                    boxShadow: 'lg',
                  }}>
                  Follow on Linkedin
                </Button>
              </Link>


            </Stack>
          </Box>
        </Box>
      </Stack>

      {/* <Stack spacing={3}>
        <Box p={10}>
          <Text>Welcome to the forefront of legal technology innovation. I am Pavol Travnik, currently deepening my expertise in AI and Machine Learning through a master's degree program, with a keen focus on transforming the legal industry through cutting-edge technology.</Text>
        </Box>
      </Stack> */}
    </SimpleGrid>

  )
}

export default SocialProfileWithImage
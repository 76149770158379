import React from 'react';
import {
  Heading,
  Text,
  Stack,
  Container,
  useColorModeValue,
} from '@chakra-ui/react'
import kupniSmlouvaImage from '../../assets/images/kupni-smlouva.png';

const BlogPage: React.FC = () => {

  return (
    <Container maxW={'5xl'} py={12}>
      <Stack spacing={4}>
        <Text
          textTransform={'uppercase'}
          color={'blue.400'}
          fontWeight={600}
          fontSize={'sm'}
          bg={useColorModeValue('blue.50', 'blue.900')}
          p={2}
          alignSelf={'flex-start'}
          rounded={'md'}>
          ChatGPT4
        </Text>
        <Heading>How to use online access of ChatGPT4 via proxy</Heading>
        <Text color={'gray.500'} fontSize={'lg'}>
          Last Updated: 2023-11-03
        </Text>
        <Text>I successfully utilized ChatGPT-4 to extract contextual data about my company from an online business register. However, ChatGPT-4, being a bot, is hindered by robots.txt restrictions. To circumvent this, I developed a simple custom proxy using Flask and Nginx.</Text>
        <Text>Why is this beneficial? Often, when preparing a contract, you need a wealth of current details, and managing this data can become a repetitive task. This is where ChatGPT excels.</Text>
        <Text>Imagine the possibilities if it could also select from various templates and access other online registers. All these repetitive tasks could be handled by AI, allowing you to simply review and control the final product.</Text>
        insert Imagine
        <img src={kupniSmlouvaImage} alt="description" />

      </Stack>
    </Container>
  );
}

export default BlogPage;
import axios from 'axios';
import { BASE_URL_BACKEND } from '../config';
axios.defaults.withCredentials = true;
axios.defaults.timeout = 5000;

// Function to get the analysis result of the uploaded PDF from the backend
export const createStripeCheckoutSession = async () => {
    try {
      const response = await axios.post(`${BASE_URL_BACKEND}/create-checkout-session`);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return { ...error.response?.data, ...{ code: error.code}};
    }
      throw error;
    }
  };
  
import React from 'react';
import {
  Heading,
  Text,
  Stack,
  Container,
  useColorModeValue,
} from '@chakra-ui/react'

const ServiceDownPage: React.FC = () => {

  return (
    <Container maxW={'5xl'} py={12}>
      <Stack spacing={4}>
        <Text
          textTransform={'uppercase'}
          color={'blue.400'}
          fontWeight={600}
          fontSize={'sm'}
          bg={useColorModeValue('blue.50', 'blue.900')}
          p={2}
          alignSelf={'flex-start'}
          rounded={'md'}>
          General Information
        </Text>
        <Heading>It seems we have service down</Heading>
        <Text color={'gray.500'} fontSize={'lg'}>
        Last Updated: 2023-09-01
        </Text>
          Please contact us at info@lawly.cz or call +420 735 876 542. We use Signal 💙 to keep communication secure.
        <Text>

        </Text>

      </Stack>
    </Container>
  );
}

export default ServiceDownPage;
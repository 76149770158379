import axios from 'axios';
import { BASE_URL_BACKEND } from '../config';
axios.defaults.withCredentials = true;
axios.defaults.timeout = 40000;

// Function to request login
export const requestSearch = async ({ query, indices: { us, ns, ks } }: { query: string, indices: {us: boolean, ns: boolean, ks: boolean} }) => {
    try {
        const response = await axios.post(`${BASE_URL_BACKEND}/search`, { query, indices: { us, ns, ks } });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return { ...error.response?.data, ...{ code: error.code}};
        }
        throw error;
    }
};

export const requestDocument = async ({ index, id }: { index: string, id: string }) => {
    try {
        const response = await axios.post(`${BASE_URL_BACKEND}/document`, { index, id });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return { ...error.response?.data, ...{ code: error.code}};
        }
        throw error;
    }
};

export const requestSimilarity = async ({ index, id }: { index: string, id: string }) => {
    try {
        const response = await axios.post(`${BASE_URL_BACKEND}/similarity`, { index, id });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return { ...error.response?.data, ...{ code: error.code}};
        }
        throw error;
    }
};


// // Function to validate login
// export const statusLogin = async () => {
//     try {
//         console.log('statusLogin', `${BASE_URL_BACKEND}/status-login`)
//         const response = await axios.get(`${BASE_URL_BACKEND}/status-login`);
//         // console.log('response statusLogin ', response)
//         return response;
//     } catch (error) {
//         if (axios.isAxiosError(error)) {
//             return { ...error.response?.data, ...{ code: error.code}};
//         }
//         throw error;
//     }
// };

// // Function to validate login
// export const logout = async () => {
//     try {
//         const response = await axios.get(`${BASE_URL_BACKEND}/logout`);
//         return response.data;
//     } catch (error) {
//         if (axios.isAxiosError(error)) {
//             return { ...error.response?.data, ...{ code: error.code}};
//         }
//         throw error;
//     }
// };

import React from 'react';
import { Tag, TagLabel, Avatar } from '@chakra-ui/react'
import ustavniSoudImg from '../assets/images/ustavni-soud-minimal.jpg';
import nejvyssiSoudImg from '../assets/images/nejvyssi-soud-minimal.jpg';
import nejvyssiSpravniSoudImg from '../assets/images/nejvyssi-spravni-soud-minimal.jpg';


interface SoudAvatarComponentProps { 
  soud: string | undefined | null
}

export const SoudAvatarComponent: React.FC<SoudAvatarComponentProps> = ({ soud }) => {

  if (!soud) return null
  if (soud === 'Ústavní soud České republiky') {
    return (
      <>
        <Tag size='lg' colorScheme='red' borderRadius='full'>
          <Avatar
            src={ustavniSoudImg}
            size='xs'
            name={soud}
            ml={-1}
            mr={2}
          />
          <TagLabel>{soud}</TagLabel>
        </Tag>
      </>
    );
  }
  if (soud === 'Nejvyšší soud') {
    return (
      <>
        <Tag size='lg' colorScheme='blue' borderRadius='full'>
          <Avatar
            src={nejvyssiSoudImg}
            size='xs'
            name={soud}
            ml={-1}
            mr={2}
          />
          <TagLabel>{soud}</TagLabel>
        </Tag>
      </>
    );
  }
  if (soud === 'Nejvyšší správní soud') {
    return (
      <>
        <Tag size='lg' colorScheme='blue' borderRadius='full'>
          <Avatar
            src={nejvyssiSpravniSoudImg}
            size='xs'
            name={soud}
            ml={-1}
            mr={2}
          />
          <TagLabel>{soud}</TagLabel>
        </Tag>
      </>
    );
  }
  return (
    <>
      <Tag size='lg' colorScheme='gray' borderRadius='full'>
        <TagLabel>{soud}</TagLabel>
      </Tag>
    </>
  );

};

export default SoudAvatarComponent;
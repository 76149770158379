import React from 'react';
import {
  Center,
  Heading,
  Text,
  Stack,
  Container,
  useColorModeValue,
} from '@chakra-ui/react'

import SocialProfileWithImage from '../components/SocialProfileWithImageComponent';

const AboutUsPage: React.FC = () => {

  return (
    <Container maxW={'5xl'} py={12}>
      <Stack spacing={4}>
        <Text
          textTransform={'uppercase'}
          color={'blue.400'}
          fontWeight={600}
          fontSize={'sm'}
          bg={useColorModeValue('blue.50', 'blue.900')}
          p={2}
          alignSelf={'flex-start'}
          rounded={'md'}>
          General Information
        </Text>
        <Heading>About us</Heading>
        <Text color={'gray.500'} fontSize={'lg'}>
          Last Updated: 2023-11-16
        </Text>

        <Heading size="md">Our Mission</Heading>
        <Text>To revolutionize the way legal professionals engage with case law. We are building an advanced AI-driven case law search platform, designed to empower lawyers, paralegals, and legal researchers with efficient, precise, and intuitive search capabilities. By leveraging AI, we aim to streamline the cumbersome process of navigating through vast legal databases, bringing forth relevant case laws at your fingertips.</Text>

        <Heading size="md">Our Vision</Heading>
        <Text>Beyond just a search tool, we are also developing a predictive tool that anticipates legal trends and outcomes, equipping legal professionals with foresight and strategic insights. This vision is rooted in the belief that AI can not only simplify existing processes but also unlock new dimensions in legal analytics and decision-making.</Text>

        <Heading size="md">Why Us?</Heading>
        <Text> With a solid foundation in AI and Machine Learning, combined with a deep understanding of the legal sector's unique challenges, we are uniquely positioned to bridge the gap between technology and law. Our platform is more than just a tool; it's a partner in your legal journey, ensuring you stay ahead in a rapidly evolving legal landscape.</Text>

        <Heading size="md">Join Our Journey</Heading>
        <Text>As we continue to refine and enhance our AI-driven solutions, we invite forward-thinking legal professionals to join us as collaborators, testers, and early adopters. Your insights and experiences are invaluable in shaping a service that not only meets but exceeds the demands of modern legal research.</Text>

        <Center><Heading >Team</Heading>
        </Center>

        <SocialProfileWithImage />


      </Stack>
    </Container>
  );
}

export default AboutUsPage;
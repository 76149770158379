import React from 'react';
import {
  Box,
  Heading,
  Text,
  Stack,
  Container,
  useColorModeValue,
} from '@chakra-ui/react'

const TermsofServicePage: React.FC = () => {

  return (
    <Container maxW={'5xl'} py={12}>
      <Stack spacing={4}>
        <Text
          textTransform={'uppercase'}
          color={'blue.400'}
          fontWeight={600}
          fontSize={'sm'}
          bg={useColorModeValue('blue.50', 'blue.900')}
          p={2}
          alignSelf={'flex-start'}
          rounded={'md'}>
          Legal Information
        </Text>
        <Heading>Terms of Service</Heading>
        <Text color={'gray.500'} fontSize={'lg'}>
          Last Updated: 2023-11-20
        </Text>

        <Box>
          <Text mb={3}>
            Welcome to the alpha phase of Lawly. These Terms of Service ("Terms") govern your use of our service, currently in the alpha testing phase. By accessing or using our service, you agree to be bound by these Terms.
          </Text>

          <Heading size="md" mb={2}>1. Acceptance of Terms</Heading>
          <Text mb={3}>
            By using Lawly, you agree to these Terms. If you do not agree to all of these Terms, do not use our service.
          </Text>

          <Heading size="md" mb={2}>2. Alpha Phase Testing</Heading>
          <Text mb={3}>
            Our service is currently in alpha phase testing. This means that you may encounter errors, bugs, and other issues. We welcome your feedback to help improve the service.
          </Text>

          <Heading size="md" mb={2}>3. Free Accounts</Heading>
          <Text mb={3}>
            During the alpha phase, accounts for Lawly are available for free. We reserve the right to change the pricing policy in the future.
          </Text>

          <Heading size="md" mb={2}>4. No Responsibility</Heading>
          <Text mb={3}>
            We provide Lawly “as is” and “as available” without any warranties. We serve no responsibility for any harm or damage resulting from the use of our service in its testing mode. Use of our service is at your own risk.
          </Text>

          <Heading size="md" mb={2}>5. Changes to Terms of Service</Heading>
          <Text mb={3}>
            We reserve the right to modify or replace these Terms at any time. We will provide notice of any changes by updating the date at the top of these Terms.
          </Text>

          <Heading size="md" mb={2}>6. Contact Us</Heading>
          <Text mb={3}>
            If you have any questions about these Terms, please contact us at info@lawly.cz.
          </Text>
        </Box>

      </Stack>
    </Container>
  );
}

export default TermsofServicePage;
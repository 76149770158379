import axios from 'axios';
import { BASE_URL_BACKEND } from '../config';
axios.defaults.withCredentials = true;
axios.defaults.timeout = 15000;  // 5 seconds timeout for all requests

// Function to upload a PDF to the backend
export const uploadPdf = async (file: File) => {
  const formData = new FormData();
  formData.append('pdf', file);
  try {
    const response = await axios.post(`${BASE_URL_BACKEND}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    // console.log('response uploadPdf ', response)
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return { ...error.response?.data, ...{ code: error.code}};
    }
    throw error;
  }
};

// Function to get the analysis result of the uploaded PDF from the backend
export const getAnalysis = async () => {
  try {
    const response = await axios.get(`${BASE_URL_BACKEND}/analysis-result`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return { ...error.response?.data, ...{ code: error.code}};
  }
    throw error;
  }
};


// Function to get user's credit
export const getUserFiles = async () => {
  try {
    const response = await axios.get(`${BASE_URL_BACKEND}/user-files`);
    // console.log('response getUserFiles ', response)
    // Returning the data received from the server
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return { ...error.response?.data, ...{ code: error.code}};
  }
    throw error;
  }
};

import React, { useState, useEffect, useRef } from 'react';
import { getUserCredit } from '../services/creditService';
import { uploadPdf, getUserFiles } from '../services/pdfService';
import { statusLogin } from '../services/authService';
import { createStripeCheckoutSession } from '../services/stripeService';
import toastAlert from './ToastComponent';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { FaFilePdf } from "@react-icons/all-files/fa/FaFilePdf";

import {
  HStack,
  Center,
  Box,
  Button, Input, useToast, Stat,
  StatLabel,
  Icon,
  Text,
  StatHelpText,
  StatArrow,
  StatGroup, Card, CardHeader, CardBody, Heading,
  CircularProgress
} from "@chakra-ui/react";

const Dashboard: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);


  const [credit, setCredit] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userID, setUser] = useState('' as string | null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [files, setFiles] = useState([]);
  // const [analysis, setAnalysis] = useState('');

  // const credit = 0;

  useEffect(() => {
    document.title = "Dashboard"
    const checkAuthStatus = async () => {
      try {
        setLoadingPage(true);
        const response = await toastAlert(() => statusLogin(), 'Authentication failed', toast, false)
        if (response.code === "ERR_NETWORK") {
          logout("/service-down")
          return
        }
        if (response.data.success) {
          setLoggedIn(true);
          setUser(response.data.user.userId);
          setLoadingPage(false);
          await getCredit();
          await getFiles();
        } else {
          logout()
        }
      } catch (error) {
        logout()
      }
    };

    checkAuthStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = async (path: string = "/signin") => {
    setLoggedIn(false);
    setUser(null);
    setCredit(0);
    navigate(path);
    setLoadingPage(false);
  };


  // Get user's credit
  const getCredit = async () => {
    const response = await getUserCredit();
    if (response.success) {
      setCredit(response.credit);
    }
  };

  // Get user's credit
  const getFiles = async () => {
    const response = await getUserFiles();
    // console.log('response getFiles before if', response.success )

    if (response.success) {
      // console.log('response getFiles', response.files )
      setFiles(response.files);
    }
  };

  const handleUpload = (event: any) => {
    setPdf(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (pdf && (pdf as any).size <= 15 * 1024 * 1024) {
      setLoading(true);
      try {
        const response = await toastAlert(() => uploadPdf(pdf), 'Upload pdf failed', toast, true)
        if (response.success) {
          setCredit(credit - 1)
          if (inputRef.current) {
            inputRef.current.value = '';
          }
          setPdf(null);
          await getFiles();
        }
      } catch (error) {
        console.log(error)
      }
      setLoading(false);
    } else {
      alert('Please select a valid PDF file no more than 15MB.');
    }
  };


  const handleBuyTokens = async () => {
    try {
      const response = await toastAlert(() => createStripeCheckoutSession(), 'Buy credits failed', toast, false)
      if (response.success) {
        window.location.href = response.url
      }
    } catch (error) {
      console.log(error)
    }
  };




  return (
    <div id="dashboard">
      {/* <LogoutButton setLoggedIn={setLoggedIn} /> */}
      {loadingPage && <Center><CircularProgress isIndeterminate color="green.300" /></Center>}

      {loggedIn && !loadingPage &&
        <Box p={8}>
          <Center>
            <HStack spacing={4}>
              <h1>Credit: {credit}</h1>
              <Button onClick={handleBuyTokens}>Buy Tokens</Button>

            </HStack>
          </Center>
          {credit > 0 && (
            <HStack spacing={4} borderWidth="1px" borderRadius="md" marginBottom={10} m={8} p={4}>
              <Input
                type="file"
                ref={inputRef}
                sx={{
                  "::file-selector-button": {
                    height: 10,
                    padding: 0,
                    mr: 4,
                    background: "none",
                    border: "none",
                    fontWeight: "bold",
                  },
                }} accept="application/pdf" onChange={handleUpload} />
              <Button onClick={handleSubmit}>Analyze</Button>
            </HStack>
          )}
          {loading && <Center><CircularProgress isIndeterminate color="green.300" /></Center>}
        </Box>}

      {loggedIn && !loadingPage &&
        files.map((item: { _id: string, prediction: number, createdAt: string, updatedAt: string, pdfFileName: string }) => (
          <div key={item._id}>
            <Box p={8}>
              <Card>
                <CardHeader>
                  <HStack>
                    <Icon w={8} h={8} as={FaFilePdf} />
                    <Box>
                      <Heading size='md'>{item.pdfFileName}</Heading>
                      <Text fontSize='xs'>ID: {item._id}</Text>
                    </Box>
                  </HStack>
                </CardHeader>
                <CardBody>
                  <StatGroup>
                    <Stat>
                      <StatLabel>Success probability</StatLabel>
                      <StatHelpText>
                        <StatArrow type='increase' />
                        {((1 - item.prediction) * 100).toFixed(4)} %
                      </StatHelpText>
                    </Stat>

                    <Stat>
                      <StatLabel>Fail probability</StatLabel>
                      <StatHelpText>
                        <StatArrow type='decrease' />
                        {((item.prediction) * 100).toFixed(4)}%
                      </StatHelpText>
                    </Stat>

                    <Stat>
                      <StatLabel>Time</StatLabel>
                      <StatHelpText>
                        {moment(item.createdAt).format('DD-MM-YYYY HH:mm')}
                      </StatHelpText>
                    </Stat>
                  </StatGroup>
                </CardBody>
              </Card>

            </Box>
          </div>
        ))}
    </div>
  );
};

export default Dashboard;
